<!--
 * @Author: CL
 * @Date: 2021-06-26 00:11:46
 * @LastEditTime: 2021-07-01 10:33:17
 * @Description: 404页面
-->

<template>
  <div class="notfind-contain">
    <img src="@/assets/404_2.gif" alt="">
  </div>
</template>

<script>
// import url from '@/assets/404_2.gif';

export default {
  data(){
    return {
      
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/mixin.less';

.notfind-contain{
  .self-fill();
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    width: 756px;
    height: 600px;
  }
}

</style>
